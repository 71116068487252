@font-face {
    font-family: 'Ubuntu';
    src: url('../../fonts/ubuntu/Ubuntu-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Ubuntu-bold';
    src: url('../../fonts/ubuntu/Ubuntu-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Ubuntu-light';
    src: url('../../fonts/ubuntu/Ubuntu-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Hurme-Black';
    src: url('../../fonts/Hurme/HurmeGeometricSans3-Black.otf');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Hurme';
    src: url('../../fonts/Hurme/HurmeGeometricSans3-Regular.otf');
    font-weight: normal;
    font-style: normal;
}

.logo{
    position: absolute;
    top: 40px;
    left: 40px;
    width: 10%;
}
.mini_container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-direction: column;
    align-content: flex-start;
    padding-left:9px
}
.left_container .register_button{
    background: #605EA6;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px 20px;
    gap: 10px;
    color: #FFFFFF;
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    width: 100%;
    margin:0 auto;
}
.text-field{
    margin-bottom: 30px !important;
}
.left_container .register_button:hover{
    background: #0E4E9E;
}
.car_image{
    width: 80%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.reg_container{
    width: 50% !important;
}
.car_container{
    width: 50% !important;
}

.register_container{
    display: flex !important;
    width: 100% !important;
    height:calc(100vh - 100px) !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    max-width: 100% !important;
    align-items: center !important;
}

.register_container > Box {
    flex: 1 !important;
}
.carousel{
    background: #ECEAF4;
    margin: 100px;
    padding: 40px;
    border-radius: 20px;
}
.left_container{
    width: 80% !important;
    height: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: flex-start;
    margin: 0 auto !important;
    align-content: flex-start;
}
.reg_container h1{
    color: #605EA6;
    font-size: 40px;
    font-weight: 700;
    line-height: 46px;
    font-family: Ubuntu-bold;
}
.register_container h2{
    padding-top:20px;
    color: #858585;
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 134.4%;
}
.register_container h3{
    font-weight: 300;
    font-family: Ubuntu-light;

}
@media only screen and (max-width: 768px) {
    .reg_container{
        width: 100% !important;
    }
    .left_container{
        padding-left: 0px !important;
    }
    .car_container {
        display:none;
    }
    .register_container h3{
        font-size: 18px;
        margin-top: 0;
    }
    .reg_container h1{
        font-size: 30px;
        line-height: 40px;
        text-transform: capitalize;
    }
    .logo{
        width: 70%;
        margin: 0 auto;
        position: initial;
        margin-bottom: 20px;
        margin-top: 40px;
    }
}