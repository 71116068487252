@font-face {
    font-family: 'Ubuntu';
    src: url('../../fonts/ubuntu/Ubuntu-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Ubuntu-bold';
    src: url('../../fonts/ubuntu/Ubuntu-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Ubuntu-light';
    src: url('../../fonts/ubuntu/Ubuntu-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.headerContainer{
    background: #ECEAF4;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
}
.h_left{
    width: 30%;
}
.h_right{
    width:40%;
}
.h_right img{
    display: block;
    margin-left: auto;
    width: 45%;
    height: 200px;
}
.left_container{
    padding-left: 30px;
}

.headerContainer h1{
    font-family: 'Hurme-Black';
    text-transform: uppercase;
    font-weight: 700;
}

.headerContainer h3{
    color:black;
    font-family: 'Hurme';
    margin-top: 0;
}