.HomeContainer{
    height: calc(100vh - 400px);
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.resultContainer{
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    margin-top: 20px;
    flex-wrap: wrap;
    align-content: space-around;
    flex-direction: column;
}

.HomeBox{
    height: 100%;
    width: 80%;
}

.HomeContainer .button{
    background-color: #605EA6;
    font-family: 'Ubuntu';
    font-weight: 700;
    width: 124px;
    height: 50px;
    font-size:16px;
    margin-top:20px;
}
.score-green{
    color: #16CEA2 !important;
}

.score-yellow{
    color: #F0D234 !important;
}

.score-gray{
    color: #E8E8E8 !important;
}

.score-red{
    color: #C63131 !important;
}

@media only screen and (max-width: 768px) {
    .regular:nth-child(-n+4) {
        display: none;
    }
    .regular{
        width:25% !important;
    }
    .primary{
        width:30% !important;
    }
    .HabitRow .SquareContainerRegular:nth-child(-n+4){
        display: none;
    }
    .SquareContainerRegular{
        width: 25%;
    }
    .SquareContainerToday{
        width: 30%;
    }
    .HabitTitleContainer{
        display:block;
        height:auto;
        position:relative;
        border-bottom: 0px solid #E9E9E9;
    }
    .HabitTitle:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 275px;
        border-bottom: 1px solid #E9E9E9;
        z-index: 1; /* Make sure the border is displayed on top of the right div */
    }
    .HabitTitle{
        display:block;
        font-size: 15px;
        line-height: 20px;
        font-style: normal;
        font-weight: 400;
        margin: 0;
        padding: 0;
        padding-bottom: 10px;
        padding-top: 10px;
    }
    .habit-boxes{
        display: flex;
        align-content: space-around;
        flex-wrap: wrap;
    }
    .square, .green, .yellow, .gray, .red{
        width: 41px;
        height: 41px;
    }
    .HabitRow{
        border-bottom: 0px solid #E9E9E9;
    }
    .HomeContainer .button{
        margin-bottom: 20px;
    }
    .today_result{
        font-size: 40px !important;
    }
    .back_dashboard{
        margin-bottom: 40px !important;
    }
    .empty-fill{
        text-transform: capitalize !important;
    }
    .left_container h1{
        text-transform: lowercase;
        margin-bottom: 5px;
    }

    .left_container h3{
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
    }
}