@font-face {
    font-family: 'Ubuntu';
    src: url('../../fonts/ubuntu/Ubuntu-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Ubuntu-bold';
    src: url('../../fonts/ubuntu/Ubuntu-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Ubuntu-light';
    src: url('../../fonts/ubuntu/Ubuntu-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.regular{
    width: 10%;
}
.primary{
    width: 20%;
}
.primary .date-box__date{
    color: #858585;
}
.date-box__date{
    font-family: Ubuntu;
    color: #cbc7c7;
    font-weight: 400;
    font-size: 18px;
    line-height: 16px;
}
.primary .date-box__num{
    color: #000000;
}
.date-box__num{
    padding-top:5px;
    color: #858585;
    font-family: Ubuntu-bold;
    font-style: normal;
    font-weight: 700;
    font-size: 31px;
    line-height: 35px;
}

.primary, .regular{
    text-align: center;
}