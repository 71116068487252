.video{
    width: 80%;
    padding:50px;
}
.profile-container{
    width: 100%;
    display:flex;
}

.profile{
    position: relative;
    width: 50%;
    margin: 50px;
    background: #F5F6F7;
    border-radius: 5px;
}
.profile-inner{
    padding: 20px;
    padding-left: 100px;
}
.profile-form{
    display:flex;
}
.profile-inner h4{
    color: #858585;
    margin: 0px;
}
.profile-detail{
    display:flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: flex-end;
    margin-bottom: 20px;
}
.profile-detail-modal{
    display:flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: flex-end;
    margin-bottom: 20px;
    width: 100%;
}
.edit-icon{
    width: 80px;
    top: 10%;
    position: absolute;
    right: 5%;
    cursor: pointer;
}
.average-container{
    width: 90%;
    border: 2px solid #F5F5F5;
    border-radius: 5px;
    margin: 0 auto;
    padding: 3%;
}

.profile-modal .btn-green{
    margin-right: 0px !important;
}

@media only screen and (max-width: 768px) {
    .profile-container{
        width: 100%;
        display:block;
    }
    .prof_video_container{
        width: 100% !important;
        display:block;
        position: relative;
    }
    .video{
        width: 90%;
        display:block;
        margin-left: auto;
        margin-right: auto;
        padding: 0;
        margin-top: 40px;
    }
    .profile{
        width: 100% !important;
        margin: 0px;
        margin-top: 50px;
    }
    .profile-inner{
        padding: 20px;
    }
    .edit-icon{
        width: 50px;
    }
    .average-container{
        margin-top:40px;
        margin-bottom: 40px;
    }
    .profile-inner h4{
        font-size: 16px;
    }
    .profile_modal{
        width: 75% !important;
    }
}