.square, .green, .yellow, .gray, .red {
    width: 48px;
    height: 51px;
    border-radius: 5px;
    margin: 10px;
    float: left;
    padding-right: 10px;
}
.SquareContainerRegular{
    width:10%;
    display: flex;
    justify-content: space-around;
}
.SquareContainerToday{
    width:20%;
    display: flex;
    justify-content: space-around;
    box-sizing: border-box;
}

.SquareContainerToday .gray{
    border: 1px dashed #CFCFCF;
    background-color: transparent;
    display: flex;
    align-content: space-around;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-left: 5px;
    padding-right: 5px;
}

.SquareContainerToday .green{
    background: rgba(22, 117, 229, 0.25);
    border: 1px dashed #1675E5;
    display: flex;
    align-content: space-around;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-left: 5px;
    padding-right: 5px;
}
.checkmarkSVG{
    padding-top: 3px;
}
.green{
    background-color: #16CEA2;
}

.yellow{
    background-color: #F0D234;
}

.gray{
    background-color: #E8E8E8;
}

.red{
    background-color: #C63131;
}