body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: 'Ubuntu';
  src: url('./fonts/ubuntu/Ubuntu-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Ubuntu-bold';
  src: url('./fonts/ubuntu/Ubuntu-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Ubuntu-light';
  src: url('./fonts/ubuntu/Ubuntu-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Hurme-Black';
  src: url('./fonts/Hurme/HurmeGeometricSans3-Black.otf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Hurme';
  src: url('./fonts/Hurme/HurmeGeometricSans3-Regular.otf');
  font-weight: normal;
  font-style: normal;
}
h1{
  font-family: Hurme-Black;
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 48px;
}

h2{
    font-family: Hurme-Black;
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 28px;
    color:black;
}

h3{
  font-family: Hurme-Black;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 20px;
  color:black;
}

h4{
  font-family: Hurme;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media only screen and (max-width: 768px) {
  .headerContainer .h_left{
    width: 100%;
  }

  .appbar {
    padding-top:20px;
    margin-bottom: 20px;
    background-color:white !important;
    box-shadow: none !important;
    display: block !important;
  }
  .appbar .mobileDrawerIconHolder {
    justify-content: space-between;
  }
  .headerContainer{
    margin-top: 45px;
  }
  .headerContainer .h_right{
    display: none;
  }
}
