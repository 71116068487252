.green-progress span{
    background-color: #16CEA2 !important;

}

.yellow-progress span{
    background-color: #F0D234 !important;

}

.gray-progress span{
    background-color: #E8E8E8 !important;

}
.history-accordion:before{
    content:"" !important;
    position: initial !important;
}
.red-progress span{
    background-color: #C63131 !important;

}
.eod-basic{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
}

.basic{
    width: 27%;
    padding-top: 20px;
}
.basic h3, .extra h3{
    font-family: Hurme-Black;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    color:black;
}

.basic h4, .extra h4{
    font-family: Hurme;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
}

.eod-extra{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
    border-top: 1px solid #E8E8E8;
}

.eod-extra .extra{
    width: 27%;
    padding-top: 20px;
}
@media only screen and (max-width: 768px) {
    .eod-basic{
        flex-direction: column;
    }
    .eod-extra{
        display:block;
    }
    .eod-extra .extra{
        width: 100%;
    }
    .basic{
        width: 100%;
    }
    .extra{
        width: 100%;
    }
    .his_date{
        width: 100% !important;
    }
}
