.tracker-container{
    width: 100%;
    display: flex;
    margin-top: 20px;
}
.habit-details{
    width: 25%;
    display: flex;
    align-content: flex-center;
    flex-wrap: wrap;
}

.habit-boxes{
    width:75%
}
.HabitTitleContainer{
    width: 100%;
    border-bottom: 1px solid #E9E9E9;
    height: 73px;
}

.HabitTitle{
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    color: #000000;
    padding-top: 15px;
}
.HabitRow{
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid #E9E9E9;
}