.container{
    width: 100%;
    display: flex;
    margin-top: 20px;
}
.spacer{
    width: 25%;
}
.week-box{
    display:flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: 75%;
}