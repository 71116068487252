@font-face {
    font-family: 'Ubuntu';
    src: url('../../fonts/ubuntu/Ubuntu-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Hurme-Black';
    src: url('../../fonts/Hurme/HurmeGeometricSans3-Black.otf');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Hurme';
    src: url('../../fonts/Hurme/HurmeGeometricSans3-Regular.otf');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Ubuntu-bold';
    src: url('../../fonts/ubuntu/Ubuntu-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Ubuntu-light';
    src: url('../../fonts/ubuntu/Ubuntu-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
.login-logo{
    width:70%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.loginBackground{
    display: flex;
    width: 100%;
    height:calc(100vh);
    background-repeat: no-repeat;
    background-size: cover;
}

.loginBox{
    background-color:white;
    padding: 70px 70px 85px 70px;
    border-radius: 20px;
}
.welcome-login{

    font-family: 'Hurme-Black';
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    color: #605EA6;
    text-transform: uppercase;

}
.empty-fill{
    -webkit-text-stroke: 1px #605EA6;
    text-stroke: 1px #605EA6;
    color: transparent;
}
.loginBackground h1{
    font-size: 40px;
    font-weight: 700;
    line-height: 46px;
    font-family: Ubuntu-bold;
}
.loginBackground h3{
    color:#858585;
    font-weight: 300;
    font-family: Hurme;

}
.register{
    font-family: Hurme;
    line-height: 19px;
    font-weight: 700;
    text-decoration: underline;
    cursor: pointer;
    color:black;
}

.loginButton{
    font-family: 'Ubuntu-bold' !important;
    background-color: #605EA6 !important;
    font-weight: bold;
    border-radius: 10px !important;
    height: 50px !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 15px 20px !important;
    gap: 10px !important;
    font-size: 21px !important;
    text-transform: inherit !important;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .loginBackground h1{
        font-size: 30px;
        line-height: 40px;
        text-transform: capitalize;
    }
    .loginBackground h3{
        font-size: 15px;
        line-height: 20px;
    }
}