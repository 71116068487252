@font-face {
    font-family: 'Ubuntu';
    src: url('../../fonts/ubuntu/Ubuntu-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.ubuntu span{
    font-family: 'Ubuntu';
}
.widget-title{
    margin-left: 10px;
    padding-bottom: 20px;
}
.widget-title span{
    font-size: 20px;
}
.menudrawer svg{
    padding-top: 7px;
}

.last-list-item{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0
}

.drawer_logo{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 30%;
}