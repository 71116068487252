.eod_rate_container:after{
    content: "Rate from 1 to 10";
    position: absolute;
    background-color: white;
    top: -10px;
    left: 30px;
    width: 150px;
    text-align: center;
    font-family: 'Hurme';
    color: #605EA6;
}
.eod_rate_container{
    border: 1px solid #605EA6;
    border-radius: 10px;
    padding:40px;
}
.eod-container .eod-h4{
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
}
.eod-container{
    width: 100%;
}
.radio-box{
    margin-top:10px;
    margin-right: 10%;
    margin-bottom: 40px;
}
.radio-box label:focus{
    color: #605EA6 !important;
}
.eod-box{
    width: 24%;
    margin-right:100px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.btn-gray, .btn-red, .btn-gray, .btn-green{
    margin-right: 30px !important;
    margin-top: 10px !important;
    padding: 15px 20px;
    gap: 10px;
    width: 135px;
    height: 50px;
    font-family: 'Hurme-Black' !important;
    font-weight: 900 !important;
}
.eod-container .button{
    background-color: #605EA6;
    font-family: 'Ubuntu';
    font-weight: 700;
    width: 124px;
    height: 50px;
    font-size:16px;
    margin-top:20px;
}

.eod-container .button:hover{
    background-color: #605EA6;
    color: white;
}

.btn-green{
    background-color: #00BFA6 !important;
}

.btn-green:hover{
    background-color: #00BFA6 !important;
    color: white !important;
}

.btn-gray{
    background-color: #E8E8E8 !important;
    color: black !important;
}
.btn-gray:hover{
    background-color: #E8E8E8 !important;
    color: black !important;
}

.btn-red{
    background-color: #C63131 !important;
}
.btn-red:hover{
    background-color: #C63131 !important;
    color:white !important;
}
@media only screen and (max-width: 768px) {
    .eod-box {
        width: 100%;
        margin-right: 0px;
        margin-bottom: 10px;
    }
    .radio-box{
        margin-right: 0px;
    }
    .eod_radio_individual{
        padding: 20px 4px !important;
    }
    .eod_rate_container{
        padding: 20px 15px !important;
    }
    .last_eod{
        width: 100% !important;
    }
    .eod-container .button{
        margin-bottom: 40px;
    }
}